.navIcons {
  display: none;
}

.notificationBox {
  position: fixed;
  top: 0;
  transform: translate(18rem, 0);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  background: #fff;
}

.scrollNotifications {
  height: 60vh;
  overflow: auto;
  overflow-x: hidden;
}

.activeBox {
  transform: translate(0, 4.1rem);
}

.notification:hover {
  background: #f1f1f1;
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  .activeBox {
    transform: translate(-1.5rem, 5.2rem);
  }
}

.btnWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #444;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  z-index: 30;
}

@media screen and (max-width:800px) {

  .hideOnSM {
    display: none !important;
  }

  .largenOnSM {
    display: inline-block;
    width: 24% !important;
    padding-top: 9px;
  }

  .navIcons {
    display: inline-block;
    margin-top: 10px;
    margin-right: 1.5rem;
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    color: #222;
    z-index: 70;
    box-shadow: 1px 1px 4px 3px #ccc;
    padding: 12px 20px;
  }

}
