.profilePage {
    /* height: 1470px */
    height: 600px

}

.profileHead {
  /* height: 96vh; */
  height:65vh;
}



.profileCover {
    height: 35vh
}

@media screen and (min-width: 1024px){
    .profilePage {
        /* height: 1250px */
        height: 1000px

    }

    .profileHead {
        max-height: 69vh;
    }

    .profileCover {
        height: 42vh;
    }
}