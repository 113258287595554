@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'avenir next medium', 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebebebcc !important;
}

*::-webkit-scrollbar,
#editAudience::-webkit-scrollbar {
  width: 4px;
  height: 1px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track,
#editAudience::-webkit-scrollbar-track {
  background: #dfdfdf;
}

*::-webkit-scrollbar-thumb,
#editAudience::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #7c7c7c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ui-datepicker-calendar {
  display: none;
}

@keyframes hoverAnimation {
  0% {
    background-color: rgba(0, 106, 179, 0);
    color: white;
  }
  100% {
    background-color: rgba(85, 188, 114, 1);
  }
  /* 20% {
    background-image: radial-gradient(#006AB3 20%, #fff 0%);
    background-size: 20% 20%;
  }
  30% {
    background-image: radial-gradient(#006AB3 30%, #fff 0%);
    background-size: 30% 30%;
  }
  40% {
    background-image: radial-gradient(#006AB3 40%, #fff 0%);
    background-size: 40% 40%;
  }
  50% {
    background-image: radial-gradient(#006AB3 50%, #fff 0%);
    background-size: 50% 50%;
  }
  60% {
    background-image: radial-gradient(#006AB3 60%, #fff 0%);
    background-size: 60% 60%;
  }
  70% {
    background-image: radial-gradient(#006AB3 70%, #fff 0%);
    background-size: 70% 70%;
  }
  80% {
    background-image: radial-gradient(#006AB3 80%, #fff 0%);
    background-size: 80% 80%;
  }
  90% {
    background-image: radial-gradient(#006AB3 90%, #fff 0%);
    background-size: 90% 90%;
  }
  100% {
    background-image: radial-gradient(#006AB3 100%, #fff 0%);
    background-size: 100% 100%;
    color:white
  } */
}
.cardAnim:hover {
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-name: hoverAnimation;
  animation-timing-function: ease-in-out;
}

.cardAnim:hover sup {
  color: white;
}
.cardAnim:hover p {
  color: white;
}
.cardAnim:hover span {
  color: white;
}
