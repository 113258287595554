.addOnMobileOnly {
  display: none;
}

.title {
  display: block;
  color: #333;
}

@media screen and (min-width: 1250px) {
}

@media screen and (max-width: 725px) {
  .container1 {
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
  }

  .container2 {
    width: 90% !important;
    height: 66% !important;
    overflow: auto;
    background-color: #fff;
  }

  .box1 {
    display: none !important;
  }

  .box2 {
    width: 100% !important;
  }

  .addOnMobileOnly {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
  }

  .addOnMobileOnly .logo {
    width: 13.5rem;
    height: 4.5rem;
    margin: -1rem 2rem 2rem 2rem;
  }

  .addOnMobileOnly .langSection {
    display: flex;
    margin-bottom: 0.3rem;
  }

  .addOnMobileOnly .langSection > h5 {
    margin: 0 12px;
    color: white;
  }
}

@media screen and (max-width: 470px) {
  .container1 {
    flex-direction: column;
  }

  .container2 {
    width: 93% !important;
    height: 66% !important;
  }

  .box1 {
    display: none !important;
  }

  .box2 {
    width: 100% !important;
  }

  .addOnMobileOnly .logo {
    width: 12rem;
    height: 3rem;
    margin: 2rem 0 0.7rem 0;
  }

  .addOnMobileOnly > h3 {
    font-size: 1.9rem;
    font-weight: 500;
  }

  .addOnMobileOnly .langSection > h5 {
    font-size: 1.1rem;
  }

  .title {
    display: none;
  }
}

/* User Select Account Type Styles */

.active_box {
  box-shadow: #168f06a9 0px 2px 16px 0px, #168f06a9 0px 2px 8px 0px;
  margin-left: 0.5rem;
}
