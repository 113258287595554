.chartTitle {
  font-size: 22px;
  text-align: center;
  margin-top: 1rem;
}

/* styles for dashboard tabs */
.tab {
  flex-grow: 1;
  background: #fff;
  padding: 10px 3px;
  text-align: center;
  border-bottom: 4px solid #f1f1f1;
  cursor: pointer;
}

.activeTab {
  border-bottom: 4px solid #55BC72;
}

/* styles for step card component */



.step {
  display: none
}


@media screen and (max-width:640px) {
  .leaf {
    display: none !important;
  }

  .stepContainer {
    width: 100% !important;
  }

  .stepContainer button {
    margin: 20px;
  }

  .stepContainer span {
    margin-left: 20px;
  }

  .stepContainer .step {
    width: 100% !important;
    display: flex;
    text-align: center;
    background: linear-gradient(to right, #b4e391 0%, #61c419 50%, #b4e391 100%);
    padding: 12px;
    border-radius: 12px;
  }

  .stepContainer .step h6 {
    font-weight: 500;
    color: #fff;
  }

  .stepContainer .step h6 span {
    font-weight: bold;
    margin: 0 8px;
  }

  /* increase width of report button in adlist data table */
  .incOnSM {
    width: 14rem;
  }


}


@media screen and (max-width:780px) {

  /* increase width of report button in adlist data table */
  .incOnSM {
    width: 14rem;
  }

}

@media screen and (max-width: 300px) {
  .stepContainer .step {
    padding: 12px 6px;
  }

  .stepContainer .step h6 {
    font-size: 20px;
  }

  .chartTitle {
    font-size: 16px;
  }

}

@media screen and (max-width: 500px) {
  .chartTitle {
    font-size: 17px;
  }

}
